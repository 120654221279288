/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import type { LoaderFunction, MetaFunction } from '@remix-run/node';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoginSchemaType, LoginSchema } from '~/schemas/auth.schema';
import { Link, json, useLocation, useNavigate } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import i18next from '~/i18next.server';
import { UserRole_Enum, useLoginLazyQuery } from '@hasura/__generated__';
import { RiInformationLine } from '@remixicon/react';
import { Button, buttonVariants } from '~/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormInput,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { getTokens, getUser, setTokens } from '~/utils/localStorage';
import AuthLayout from '~/layout/auth';
import { Checkbox } from '~/components/ui/checkbox';
import { cn } from '~/utils/tw';
import { useEffect, useState } from 'react';
import { validationErrorHandler } from '~/utils/errorHandler';
import { Alert, AlertDescription } from '~/components/ui/alert';
import { useApolloClient } from '@apollo/client';
import { prefixUrlWithRole } from '~/utils/navigation';
import { LazyImage } from '~/components/lazy-image';

export const loader: LoaderFunction = async ({ request }) => {
  const locale = await i18next.getLocale(request);
  const t = await i18next.getFixedT(request, 'common');
  const title = t('pages.login.title');
  const description = t('pages.login.description');
  return json({ locale, title, description });
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
    { title: data?.title },
    { name: 'description', content: data?.description },
  ];
};

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);
  const { cache } = useApolloClient();

  useEffect(() => {
    const tokens = getTokens();
    const { role, id: userId } = getUser();
    const isLoggedIn =
      !!role && !!tokens.accessToken && !!tokens.refreshToken && !!userId;
    if (isLoggedIn) {
      if (role === UserRole_Enum.User) {
        return navigate('/search');
      }
      navigate(prefixUrlWithRole('/dashboard', role));
    }
  }, [navigate]);

  const [login, { loading }] = useLoginLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      cache.reset();
      setTokens({
        accessToken: data.login.accessToken,
        refreshToken: data.login.refreshToken,
      });
      navigate(`/`);
    },
    onError: (error) => {
      validationErrorHandler(error).forEach((error) => {
        form.setError(
          'email',
          {
            type: 'manual',
            message: error.message,
          },
          { shouldFocus: true },
        );
        form.setError(
          'password',
          {
            type: 'manual',
            message: error.message,
          },
          { shouldFocus: true },
        );
      });
    },
  });

  const form = useForm<LoginSchemaType>({
    resolver: zodResolver(LoginSchema),
  });

  const onSubmit: SubmitHandler<LoginSchemaType> = ({ email, password }) =>
    login({ variables: { email, password } });

  /**
   * @NOTE
   * This really shouldn't be necessary but for some reason
   * the expected browser's default behaviour of submitting
   * the form when pressing `Enter` isn't happening, so this
   * forces it to happen. It happens for every form on the app.
   * Which is really not ideal but is it also not preventing
   * the app from working as it should as such it isn't critical.
   * This works for now but one day we should figure out what
   * is stopping the default behaviour from happening
   */
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      form.handleSubmit(onSubmit)();
    }
  };

  return (
    <AuthLayout
      sideContent="right"
      content={
        <>
          {t('pages.login.hi')} <br /> {t('pages.login.welcome')}
        </>
      }
      background="clouds"
    >
      <div>
        {location?.state?.alert && (
          <Alert variant={location?.state?.alert.status} className="mb-6">
            <AlertDescription>
              {location?.state?.alert.message}
            </AlertDescription>
          </Alert>
        )}

        <div className="flex flex-col items-start">
          <LazyImage src="/svg/logo.svg" alt="Homy logo" className="mb-6" />

          <p className="h4">{t('pages.login.title')}</p>

          <div className="w-full">
            <Form {...form}>
              <form
                onKeyDown={handleKeyDown}
                onSubmit={form.handleSubmit(onSubmit)}
                className="flex flex-col gap-6"
              >
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel htmlFor="email">{t('labels.email')}</FormLabel>
                      <FormControl>
                        <FormInput
                          id="email"
                          aria-describedby="email"
                          placeholder={t('labels.email')}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <div className="flex justify-between">
                        <FormLabel
                          htmlFor="password"
                          className="leading-normal"
                        >
                          {t('labels.password')}
                        </FormLabel>

                        <Link
                          to="/forgot-password"
                          className={cn(
                            buttonVariants({ variant: 'link' }),
                            'h-fit p-0 align-baseline text-sm leading-normal',
                          )}
                        >
                          {t('pages.forgotPassword.title')}?
                        </Link>
                      </div>
                      <FormControl>
                        <FormInput
                          id="password"
                          aria-describedby="password"
                          placeholder={t('labels.password')}
                          type={showPassword ? 'text' : 'password'}
                          endIcon={{
                            icon: <RiInformationLine />,
                            tooltip: (
                              <div>
                                <p>
                                  {t(
                                    'pages.login.passwordValidation.shouldHave',
                                  )}
                                </p>
                                <ul className="list-disc pl-6">
                                  <br />
                                  <li>
                                    {t(
                                      'pages.login.passwordValidation.minCharacters',
                                    )}
                                  </li>
                                  <li>
                                    {t(
                                      'pages.login.passwordValidation.oneUppercase',
                                    )}
                                  </li>
                                  <li>
                                    {t(
                                      'pages.login.passwordValidation.oneSpecial',
                                    )}
                                  </li>
                                  <li>
                                    {t(
                                      'pages.login.passwordValidation.oneNumber',
                                    )}
                                  </li>
                                </ul>
                              </div>
                            ),
                          }}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Checkbox
                  id="showPassword"
                  checked={showPassword}
                  onCheckedChange={() => setShowPassword(!showPassword)}
                  label={t('labels.showPassword')}
                />

                <div className="flex flex-col items-start">
                  <Button type="submit" className="w-full" loading={loading}>
                    {t('inputs.signIn')}
                  </Button>
                  <div className="mt-2 flex items-center gap-2">
                    <p className="text-sm text-homy-gray-light">
                      {t('pages.login.registerLink')}
                    </p>
                    <Link
                      to="/user/register"
                      className={cn(
                        buttonVariants({ variant: 'link' }),
                        'h-fit px-0 py-2 text-sm',
                      )}
                    >
                      {t('pages.login.signUp')}
                    </Link>
                  </div>
                </div>
              </form>
            </Form>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}
